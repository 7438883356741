import React, { createRef, useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import throttle from 'lodash/throttle'

import Heading from '@components/Heading'
import Section from '@components/Section'
import IntersectionObserver from '@components/IntersectionObserver'
import Sticky, { StickyState } from '@components/Sticky'
import Image from '@components/Image'
import { ContactContext } from '@components/Contact/Contact.Context'

import media from '@styles/media'
import { getWindowDimensions } from '@utils'

import HomeServicesMobile from './Home.Services.Mobile'

export const services = [
  {
    heading: 'UI & Web',
    list: [
      'Interface Design',
      'Prototyping',
      'Frontend coding',
      'Design system crafting',
      'Responsive websites',
      'Content management systems',
      'Cross-platform apps',
    ],
    link: {
      text: 'Let’s talk about your app',
      to: '/contact',
    },
  },
  {
    heading: 'UX',
    list: [
      'User / Persona impersonation',
      'Customer journeys',
      'A/B Testing',
      'Nurturing and onboarding',
    ],
    link: {
      text: 'Let’s try the shoes of your users',
      to: '/contact',
    },
  },
  {
    heading: 'Brand',
    list: [
      'Visual identity',
      'Content strategy',
      'Print communication',
      'Conversion optimization',
    ],
    link: {
      text: 'Let’s refresh consistently your image',
      to: '/contact',
    },
  },
]

const imageQuery = graphql`
  query SerivesImageQuery {
    first: file(name: { regex: "/skills_desktop_01/" }) {
      childImageSharp {
        fluid(maxWidth: 787, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    second: file(name: { regex: "/skills_desktop_02/" }) {
      childImageSharp {
        fluid(maxWidth: 787, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    third: file(name: { regex: "/skills_desktop_03/" }) {
      childImageSharp {
        fluid(maxWidth: 787, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const calculateActive = (progress: number) => (index: number): boolean => {
  const total = services.length
  const nextThreshold = ((100 / total) * (index + 1)) / 100
  const threshold = ((100 / total) * index) / 100

  if (index === 0) {
    return progress < nextThreshold
  }

  if (index === 2) {
    return progress > threshold
  }

  return progress > threshold && progress < nextThreshold
}

const calculateOffset = (progress: number) => {
  if (typeof window === 'undefined') return 0

  if (document.getElementById('grid-value')) {
    const gridElement = document.getElementById('grid-value') as HTMLElement
    const { height, top } = gridElement.getBoundingClientRect()

    const total = (height + 45) * 2
    let offset = total * progress

    if (progress < 0) {
      offset = 0
    }

    return {
      height,
      offset,
      top,
    }
  }

  return {}
}

const calcOpacity = (
  entering: boolean = true,
  top: number = 0
): { opacity?: number } => {
  const opacity = Math.abs(top) > 250 ? 0 : 1 - Math.abs(top) / 250
  return entering ? {} : { opacity }
}

const calcTransform = (offset: number): string =>
  `translateY(${offset * 180}px)`

function getTime() {
  const now = new Date()
  const offsetUtc = 60000 * now.getTimezoneOffset()
  const nowSofia = new Date(now.getTime() + offsetUtc + 180 * 60000)

  const hours = now.getHours()
  const hoursSofia = nowSofia.getHours()
  const days = ['Sun.', 'Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat.']
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Nov',
    'Dec',
  ]

  return {
    month: months[now.getMonth()],
    date: now.getDate(),
    day: days[now.getDay()],
    hours: hours,
    minutes: ('0' + now.getMinutes()).slice(-2),
    hoursSofia: hoursSofia,
    minutesSofia: ('0' + nowSofia.getMinutes()).slice(-2),
  }
}

function Time() {
  const [time, setTime] = useState(getTime())

  useEffect(() => {
    const tick = setInterval(() => {
      setTime(getTime())
    }, 1000)

    return () => {
      clearInterval(tick)
    }
  }, [])

  return (
    <TimeContainer>
      <Digits>
        {time.hours}:{time.minutes}
      </Digits>
      <FullDate>
        {time.day},{time.month} {time.date}
      </FullDate>
      <OffsetContainer>
        <OffsetLocation>Sofia</OffsetLocation>
        <OffsetTime>
          {time.hoursSofia}:{time.minutesSofia} {}
        </OffsetTime>
      </OffsetContainer>
    </TimeContainer>
  )
}

function Code() {
  return (
    <CodeContainer>
      <VectorTop aria-hidden="true">
        {`<svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 30H22.9091V26.4595H0V30Z" fill="#111216"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00598145 24.7176L7.01982 19.7873L7.01897 15.2965L0.00598145 10.3745V24.7176Z" fill="#111216"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8917 0L15.8492 4.87412V9.29375L22.894 14.2569L22.8918 0H22.8917Z" fill="#111216"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0065918 0V8.62637L22.8961 24.7297L22.8948 16.0316L0.0065918 0Z" fill="#111216"/>
          </svg>`}
      </VectorTop>
      <VectorBottom aria-hidden="true">
        {`<svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 30H22.9091V26.4595H0V30Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd
        `}
      </VectorBottom>
    </CodeContainer>
  )
}

function HomeServices() {
  const config = { mass: 1, tension: 1000, friction: 100 }

  const [props, set] = useSpring(() => ({
    offset: 1,
    config,
  }))
  const heading = createRef()
  const { showContact, toggleContact } = useContext(ContactContext)
  const { first, second, third } = useStaticQuery(imageQuery)

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (heading.current) {
        const { height } = getWindowDimensions()
        const offset = heading.current.getBoundingClientRect().top
        set({ offset: 1 - offset / height })
      }
    })

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [showContact])

  const animatedStyles = {
    transform: props.offset.interpolate(calcTransform),
    position: 'relative',
    zIndex: 2,
    pointerEvents: 'none',
  }

  return (
    <>
      <HomeServicesDesktop>
        <Section>
          <IntersectionObserver
            render={({ entering, boundingClientRect }) => {
              return (
                <animated.div style={animatedStyles}>
                  <HeadingBackground
                    style={calcOpacity(entering, boundingClientRect.top)}
                  >
                    <LargeHeading ref={heading}>
                      Now crafting mainly Interfaces, Experience & Identity...
                    </LargeHeading>
                  </HeadingBackground>
                </animated.div>
              )
            }}
          />
        </Section>
        <Sticky
          cover
          height="333vh"
          render={({ progress }: StickyState) => {
            const getActive = calculateActive(progress)
            const offset = calculateOffset(progress)

            const firstActive: boolean = getActive(0)
            const secondActive: boolean = getActive(1)
            const thirdActive: boolean = getActive(2)

            const background = firstActive
              ? '#556767'
              : secondActive
              ? '#5f6f82'
              : '#59698a'

            const progressStyles = {
              transform: `translateY(${offset.offset}px)`,
              height: '100%',
              top: 0,
              background,
            }

            return (
              <Grid>
                <ImageSlides>
                  {services.map((service, i) => {
                    const activeOrder =
                      i === 0
                        ? firstActive
                        : i === 1
                        ? secondActive
                        : thirdActive
                    const activeImage =
                      i === 0 ? first : i === 1 ? second : third

                    return (
                      <ImageSlide key={service.heading} active={activeOrder}>
                        <Image src={activeImage.childImageSharp.fluid} />
                        {i == 0 ? <Time /> : undefined}
                      </ImageSlide>
                    )
                  })}
                </ImageSlides>
                <Column>
                  {services.map((service, i) => {
                    const activeOrder =
                      i === 0
                        ? firstActive
                        : i === 1
                        ? secondActive
                        : thirdActive

                    return (
                      <Value
                        key={service.heading}
                        id={i === 0 ? `grid-value` : undefined}
                        active={activeOrder}
                      >
                        <Heading.h2>{service.heading}</Heading.h2>
                        <List>
                          {service.list.slice(0, 3).map((item) => (
                            <Item key={item}>{item}</Item>
                          ))}
                        </List>
                        <StyledLink
                          onClick={toggleContact}
                          active={activeOrder}
                          tabIndex={activeOrder ? 0 : -1}
                        >
                          {service.link.text}
                        </StyledLink>
                        {i == 0 ? (
                          <Progress style={progressStyles} />
                        ) : undefined}
                      </Value>
                    )
                  })}
                </Column>
                <Column gradient />
                <Column gradient />
                <Column />
              </Grid>
            )
          }}
        />
      </HomeServicesDesktop>
      <HomeServicesMobile />
    </>
  )
}

export default HomeServices

const HomeServicesDesktop = styled.div`
  background: #101216;
  padding-top: 60px;

  ${media.tablet`
    display: none;
  `}
`

const HeadingBackground = styled.div`
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    92.62deg,
    #eae3f1 0.99%,
    #b6bec3 34.85%,
    #b3c2dc 67.46%,
    #aac1ce 79.92%,
    #bdcddc 93.48%
  );
  color: transparent !important;
  max-width: 800px;
  padding-bottom: 400px;
  margin-bottom: -250px;
  z-index: 4;
`

const LargeHeading = styled.p`
  display: inline;
  font-weight: 900;
  font-size: 65px;
  letter-spacing: 1px;
  line-height: 1.25;
  font-family: ${(p) => p.theme.fontfamily.serif};
  background: transparent;
  color: transparent;
  padding-bottom: 200px;
`

const Grid = styled(Section)`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;

  &::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: ${(p) =>
      p.gradient
        ? `linear-gradient(
            rgba(255, 255, 255, 0.1),
            transparent 33%,
            transparent 70%,
            rgba(255, 255, 255, 0.1)
          )`
        : 'rgba(255, 255, 255, 0.1)'};
  }
`

const Value = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 45px;
  }

  h2 {
    color: ${(p) => (p.active ? '#fff' : p.theme.colors.grey)};
    transition: color 0.3s var(--ease-out-quad);
  }

  li {
    color: ${(p) => (p.active ? '#fff' : p.theme.colors.grey)};
    transition: color 0.3s var(--ease-out-quad);
  }

  ${media.desktop_medium`
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  `}

  @media screen and (max-height: 800px) {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

const Transform = styled.div`
  transform: translateY(${(p) => (p.active ? 0 : 42)}px);
  transition: transform 0.6s var(--ease-out-cubic);
`

const StyledLink = styled.button`
  font-weight: 800;
  color: ${(p) => p.theme.colors.blue};
  opacity: ${(p) => (p.active ? 0.5 : 0)};
  transition: opacity 0.3s var(--ease-out-quad);
  cursor: pointer;
  pointer-events: ${(p) => (p.active ? 'initial' : 'none')};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const List = styled.ul`
  list-style: none;
  margin-bottom: 20px;
`

const Item = styled.li`
  color: #73737d;
`

const Progress = styled.div`
  position: absolute;
  width: 1px;
  right: 0px;
  z-index: 1;
  background: ${(p) => p.theme.colors.grey};
  transition: opacity 0.3s var(--ease-out-quad);
`

const ImageSlides = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 80px;
  width: 70%;
  height: 100%;
  pointer-events: none;

  & > div {
    width: 100%;
  }
`

const ImageSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2%;
  right: 0;
  width: 88%;
  height: 100%;
  pointer-events: none;
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.3s var(--ease-out-quad);

  & > div {
    width: 100%;
  }
`

const Digits = styled.div`
  font-size: 24px;
  font-weight: 200;

  @media only screen and (max-width: 1220px) {
    font-size: 18px;
  }
`

const TimeContainer = styled.span`
  position: absolute;
  width: 80px;
  top: 51%;
  left: 20.9%;
  transform: rotate(-5.86deg);
  color: #fafafa;
  opacity: 0.7;
  text-align: center;

  @media only screen and (max-height: 800px) {
    top: 50%;
  }

  @media only screen and (max-width: 1700px) {
    left: 20.6%;
  }

  @media only screen and (max-width: 1400px) {
    left: 20%;
  }

  @media only screen and (max-width: 1220px) {
    left: 19.5%;
  }

  @media only screen and (max-width: 1140px) {
    left: 18.8%;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const FullDate = styled.div`
  position: relative;
  left: 1px;
  top: 1px;
  font-size: 7px;
  font-weight: 800;

  @media only screen and (max-width: 1220px) {
    font-size: 5px;
  }
`

const OffsetContainer = styled.div`
  opacity: 0.5;
  position: relative;
  top: 120px;
  font-size: 7px;
  font-weight: 800;
  color: #33749f;

  &:hover,
  &:focus {
    opacity: 1;
  }
  @media only screen and (max-width: 1600px) {
    top: 90px;
  }

  @media only screen and (max-width: 1300px) {
    top: 80px;
  }

  @media only screen and (max-width: 1220px) {
    font-size: 5px;
  }
`

const OffsetLocation = styled.span`
  display: block;
  font-size: 10px;
  font-weight: 400;

  @media only screen and (max-width: 1220px) {
    font-size: 8px;
  }
`

const OffsetTime = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 200;
  line-height: 1;

  @media only screen and (max-width: 1220px) {
    font-size: 16px;
  }
`

const CodeContainer = styled.span`
  display: block;
  color: #33749f;
  font-size: 6px;

  @media only screen and (max-width: 1100px) {
    font-size: 5px;
  }
`

const VectorTop = styled.div`
  position: absolute;
  left: 33%;
  top: 38%;
  max-width: 18%;

  @media only screen and (max-height: 800px) {
    top: 35%;
  }

  @media only screen and (max-width: 1100px) {
    top: 42%;
    max-width: 22%;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const VectorBottom = styled.div`
  position: absolute;
  left: 33%;
  top: 50%;
  max-width: 18%;

  @media only screen and (max-height: 800px) {
    top: 50%;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 22%;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`
